import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { toastFail, toastSuccess } from 'utils/Toast';

import Card from 'components/Card';
import ModalUsers from 'components/ModelUsers';

import './EditExclusionList.scss';

const FieldsetColumn = styled('fieldset')`
    input {
        margin-bottom: 20px;
    }
`;

type EditExclusionListModalProps = {
    GhostInclusionStore?: any;
    AuthStore?: any;
};

class EditExclusionListModal extends React.Component<EditExclusionListModalProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
        };
    }


    componentDidMount() {
        this.setState({ account: '', role: '' })
        const { GhostInclusionStore } = this.props;

        const { selectedEntry } = GhostInclusionStore;

        if (selectedEntry.id) {
            this.setState({ account: selectedEntry.account_id, role: selectedEntry.role })
        }
    }

    onSave = (e: any) => {
        const { GhostInclusionStore } = this.props
        const { form } = e.target

        // these will be HTML <input /> elements, not the raw values
        const { protected_processes } = form;

        e.preventDefault();

        const saveFn = GhostInclusionStore.creatingEntry ? GhostInclusionStore.create : GhostInclusionStore.update;

        const data:any = protected_processes.value;
        var inlist = 0;
        for (let i=0; i<GhostInclusionStore.data.length; i++) {
            if (data === GhostInclusionStore.data[i]) 
                inlist = 1;
        }
        if (inlist === 1)
            toastFail("The entry is already in the exclusion list");
        else{
            saveFn(data)
                .then(() => {
                    toastSuccess('Entry saved!');
                    this.onCancel();
                })
                .catch((e:any) => {
                    toastFail(e);
                });
        }
    }

    onCancel = () => {
        const { GhostInclusionStore } = this.props;
        GhostInclusionStore.clearSelectedEntry();
        this.setState({ account: '', role: '' })
    }

    onAssignAccountId = (id:any) => {
        this.setState({account: id})
    }

    onAssignRole = (role:any) => {
        this.setState({role: role})
    }
    
    render() {
        const { GhostInclusionStore } = this.props;
        const { selectedEntry } = GhostInclusionStore;
        const { creatingEntry } = GhostInclusionStore;

        let entry = ''
        if (creatingEntry === 0)
            entry = selectedEntry

        return (
            <ModalUsers title={creatingEntry ? 'Create Entry' : 'Edit Entry'} onClose={this.onCancel}>
                <Card>
                    <form style={{ width: '300px' }}>
                        <FieldsetColumn>
                            <label className='allow-list-label' htmlFor="randomization_interval_seconds">Keyword/phrase in alert to exclude</label>
                            <textarea id="protected_processes" name="protected_processes" defaultValue={entry}
                                    rows={5} cols={33}>
                            </textarea>
                        </FieldsetColumn>

                        <button className="btn btn-primary" type="button" onClick={this.onSave}>Save</button>
                        <button className="btn btn-secondary" type="button" onClick={this.onCancel}>Close</button>
                    </form>
                </Card>
            </ModalUsers>
        );
    }
}

export default inject('GhostInclusionStore', 'AuthStore')(observer(EditExclusionListModal));