import { createContext, useContext, useState } from "react";
import { makeAuthenticatedRequest } from "utils/API";
import { Account, CompressedAccount } from "./AuthStore";
import { ESMap } from "typescript";

interface AccountsStore {
    loading: boolean
    accounts: Account[]
    compressedAccounts: CompressedAccount[]
    accountsById: ESMap<string, Account>
    selectedAccountIndex: number
    loadAccounts: () => void
    loadCompressedAccounts: () => void
    updateAccount: (account: Account) => void
    setSelectedAccountIndex: (index: number) => void
    getAccountById: (id: string) => Promise<Account>
}

const AccountsContext = createContext<AccountsStore | undefined>(undefined)

export function useAccountsStoreContext() {
    const stores = useContext(AccountsContext)
    if (stores === undefined)
        throw new Error('useAccountsStoreContext must be use wrapped around a AccountsProvider')
    return stores
}

export default function AccountsProvider({ children }: {children: any}) {
    const [loading, setLoading] = useState<boolean>(false)
    const [accounts, setAccounts] = useState<Account[]>([])
    const [compressedAccounts, setCompressedAccounts] = useState<CompressedAccount[]>([])
    const [accountsById, setAccountsById] = useState<ESMap<string, Account>>(new Map())
    const [selectedAccountIndex, setSelectedAccountIndex] = useState<number>(-1)

    async function loadAccounts() {
        setLoading(true)
        return makeAuthenticatedRequest({ url: `/api/v2/accounts` })
            .then(onLoadAccounts)
            .catch(onApiFailure)
    }

    function onLoadAccounts(response: Account[]) {
        // Build accountsById dictionary
        const accountsDict = new Map<string, Account>()
        response.forEach((account) => {
            accountsDict.set(account.id, account)

            // Add subaccounts
            if (account.sub_accounts)
                account.sub_accounts.forEach((subaccount) => {
                    accountsDict.set(subaccount.id, subaccount)
                })
        })
        setAccountsById(accountsDict)
        setAccounts(response)
        setLoading(false)
    }

    // Fetch for accounts but only requests for id and name, saving network bandwidth
    async function loadCompressedAccounts() {
        setLoading(true)
        return makeAuthenticatedRequest({ url: `/api/v2/accounts?view=compressed` })
            .then(setCompressedAccounts)
            .catch(onApiFailure)
    }

    async function updateAccount(account: Account) {
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${account.id}`,
            options: { method: 'PATCH' },
            data: account
        })
        .then((account) => updateAccounts(account))
        .catch(onApiFailure);
    }

    // Add the new account to the accounts array comparing ids
    function updateAccounts(newAccount: Account) {
        setAccounts((accounts) => {
            const newAccounts = [...accounts]
            return newAccounts.map((oldAccount: Account) => {
                if (oldAccount.id === newAccount.id) return newAccount
                else return oldAccount
            })
        })
    }

    async function getAccountById(id: string) {
        return makeAuthenticatedRequest({ url: `/api/v2/accounts/${id}` }).catch(onApiFailure)
    }

    function onApiFailure(e: Error) {
        setLoading(false)
        throw e
    }

    return (
        <AccountsContext.Provider value={{
            loading,
            accounts,
            compressedAccounts,
            accountsById,
            selectedAccountIndex,
            loadAccounts,
            loadCompressedAccounts,
            updateAccount,
            setSelectedAccountIndex,
            getAccountById
        }}>
            { children }
        </AccountsContext.Provider>
    )
}
