import React from 'react';
import { inject, observer } from 'mobx-react';
import ExclusionListsTable from './ExclusionListTable';
import EditExclusionListModal from './EditExclusionList';
import './ExclusionList.scss';
import { toastFail } from 'utils/Toast';
import { ScreenContainer } from '../settings/Settings';
import { TitleContainer } from '../home/Home';
import { Button } from '@mui/material';

const SHOW_ALL_USERS = 'SHOW_ALL_USERS_CUSTOM_VALUE';

type ExclusionListsProps = {
    BackupExclusionStore?: any;
    UsersStore?:any;
    AuthStore?: any;
};

class BackupExclusionLists extends React.Component<ExclusionListsProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
            id: '',
        };
    }

    componentDidMount() {
        const { BackupExclusionStore, AuthStore, UsersStore } = this.props;
        BackupExclusionStore.loadData()
            .catch(() => {
                toastFail('Error loading exclusion list.');
            });
        if(this.state.id){ 
            BackupExclusionStore.loadDataById(this.state.id)
            .catch(() => {
                toastFail('Error loading exclusion list.');
            });
        }
            UsersStore.getAllAcounts();
        
        
        AuthStore.loadProfile()
            .catch((e: Error) => toastFail(e.message));

    }
    
    onAddEntry = () => {
        const { BackupExclusionStore } = this.props;
        BackupExclusionStore.setSelectedEntry("-1");
        BackupExclusionStore.creatingEntry = 1;
    }

    onAssignAccountId = (id:any) => {
        const { BackupExclusionStore } = this.props;
        this.setState({account: id})
        BackupExclusionStore.currentAccountID = id;
    }

    render() {
        const { BackupExclusionStore, AuthStore, UsersStore } = this.props;
        const { selectedEntry } = BackupExclusionStore;
        const { data } = BackupExclusionStore;
        const { accounts } = UsersStore;
        const loadingState = BackupExclusionStore.loading;

        if(AuthStore.isSupervisor){
            BackupExclusionStore.accounts?.items?.forEach((item:any) => {
                item['account_name'] = item.name
            });
        }

        return (
            <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}} className="Users">
                <TitleContainer>
                    <div>
                        <h1> Backup Exclusion List</h1>
                        <span className="total-entries">Number of Entries: {data.length}</span>
                    </div>
                    <Button
                        variant='contained'
                        onClick={this.onAddEntry}
                        style={{ height: '50px', marginBottom: '10px', float: 'right' }}
                    >
                        <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                        Add Entry
                    </Button>
                </TitleContainer>
                    
                <div className='account-selection'>
                    <label>Select an Account</label>
                    <select
                        name="account"
                        id="account"
                        value={this.state.account}
                        onChange={(e) => {
                            this.onAssignAccountId(e.target.value)
                            BackupExclusionStore.loadDataById(e.target.value)

                        }}
                        style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                    >
                        <option disabled value=""> Select an Account </option>
                        { accounts ? accounts.map((account:any) =>
                                <option key={account.id} value={account.id}>{account.name}</option>
                        )
                        : 
                        <p>No Options Available</p>}
                    </select>
                    <ExclusionListsTable 
                        dataTable={BackupExclusionStore.data} 
                        loadingState={loadingState} 
                        noDataMessage={'Please select an account to see it\'s exclusion list entries.'} 
                        showAccountName={this.state.account === SHOW_ALL_USERS ? true : false}
                        />
                </div>           

                {selectedEntry && <EditExclusionListModal />}
                
            </ScreenContainer>
        );
    }
}

export default inject('BackupExclusionStore','AuthStore','UsersStore')(observer(BackupExclusionLists));