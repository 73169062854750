import { Button, Collapse, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, styled, useTheme } from "@mui/material";
import StyledSelect from "components/StyledSelect";
import PROPERTIES from "properties";
import { useEffect, useState } from "react";
import { useAccountsStoreContext } from "stores/AccountsProvider";
import { StyledLink, StyledSwitch, TitleContainer } from "../home/Home";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GrWindows } from "react-icons/gr";
import { VscTerminalLinux } from "react-icons/vsc";
import { Account } from "stores/AuthStore";

const { SETTINGS, ACCOUNTS } = PROPERTIES

export default function Settings() {

    const [openPrevention, setOpenPrevention] = useState<boolean>(true)
    const [openDetection, setOpenDetection] = useState<boolean>(true)
    const [openRecovery, setOpenRecovery] = useState<boolean>(true)
    const [enableAll, setEnableAll] = useState<boolean>(true)
    const [selectedAccount, setSelectedAccount] = useState<Account>()


    const accountsStore = useAccountsStoreContext()

    const { selectedAccountIndex, compressedAccounts } = accountsStore

    const communityEditionEnvVar = process.env.REACT_APP_COMMUNITY_EDITION
    const communityEdition = communityEditionEnvVar ? communityEditionEnvVar.toLowerCase() === 'true' : false

    useEffect(() => {
        accountsStore.loadCompressedAccounts()
        
        // Reset account index if 'All acounts' was selected in previous screen
        if (selectedAccountIndex === -1)
            accountsStore.setSelectedAccountIndex(0)
    }, [])

    useEffect(() => {
        if (compressedAccounts.length > 0){
            if(selectedAccountIndex == -1){
                accountsStore.getAccountById(compressedAccounts[0].id)
                .then(setSelectedAccount)
            }else{
                accountsStore.getAccountById(compressedAccounts[selectedAccountIndex].id)
                .then(setSelectedAccount)
            }
        }
    }, [compressedAccounts, selectedAccountIndex])

    function handleEnableAllClick() {
        if (!selectedAccount) return

        if (enableAll) {
            selectedAccount.filesystem_decoys = 2
            selectedAccount.encryption_key_capture = 1
            if (!communityEdition) {
                selectedAccount.protection_zerotrust_ringfence = 1
                selectedAccount.protection_memory = 2
                selectedAccount.protection_system = 2
                selectedAccount.protection_simple_powershell = 2
                selectedAccount.visibility_memory = true
                selectedAccount.visibility_system = true
                selectedAccount.visibility_simple_powershell = true
                selectedAccount.intelligent_file_entropy_analysis = 2
                selectedAccount.ransomware_protection_backup = true
                //selectedAccount.lock_ransomware_protection_backup = true should preserve state of lock backup regardless of enable all
            }
        } else {
            selectedAccount.filesystem_decoys = 0
            selectedAccount.encryption_key_capture = 0
            if (!communityEdition) {
                selectedAccount.protection_zerotrust_ringfence = 0
                selectedAccount.protection_memory = 0
                selectedAccount.protection_system = 0
                selectedAccount.protection_simple_powershell = 0
                selectedAccount.visibility_memory = false
                selectedAccount.visibility_system = false
                selectedAccount.visibility_simple_powershell = false
                selectedAccount.intelligent_file_entropy_analysis = 0
                selectedAccount.ransomware_protection_backup = false
                //selectedAccount.lock_ransomware_protection_backup = false should preserve state of lock backup regardless of disable all
            }
        }
        accountsStore.updateAccount(selectedAccount)
        setEnableAll((value) => !value)
    }

    function setFileEntropyAnalysisAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.intelligent_file_entropy_analysis = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setContentAnalysisAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.advanced_content_analysis= value
        accountsStore.updateAccount(selectedAccount)
    }

    function setMemoryProtectionAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.protection_memory = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setSystemProtectionAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.protection_system = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setSimplePowershellProtectionAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.protection_simple_powershell = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setMemoryNotificationsAndUpdate(value: boolean) {
        if (!selectedAccount) return
        selectedAccount.visibility_memory = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setSystemNotificationsAndUpdate(value: boolean) {
        if (!selectedAccount) return
        selectedAccount.visibility_system = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setSimplePowershellNotificationsAndUpdate(value: boolean) {
        if (!selectedAccount) return
        selectedAccount.visibility_simple_powershell = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setFilesystemDecoyAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.filesystem_decoys = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setRansomwareProtectionAndUpdate(value: boolean) {
        if (!selectedAccount) return
        selectedAccount.ransomware_protection_backup = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setLockRansomwareProtectionAndUpdate(value: boolean) {
        if (!selectedAccount) return
        selectedAccount.lock_ransomware_protection_backup = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setEncryptionKeyCaptureAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.encryption_key_capture = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setGhostingProtectionAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.protection_zerotrust_ringfence = value
        accountsStore.updateAccount(selectedAccount)
    }

    function setRefreshProtectionAndUpdate(value: number) {
        if (!selectedAccount) return
        selectedAccount.backup_refresh_period = value
        accountsStore.updateAccount(selectedAccount)
    }

    const selectOptions = compressedAccounts.map((account, index) => {
        return { value: index, label: account.name }
    })

    const optionsForProtection = [
        { value: 0, label: ACCOUNTS.PROTECTION_OPTIONS.OFF },
        { value: 1, label: ACCOUNTS.PROTECTION_OPTIONS.ONLY_DETECTION },
        { value: 2, label: ACCOUNTS.PROTECTION_OPTIONS.DETECTION_AND_BLOCKING }
    ]

    const optionsForRefresh = [
        { value: 24, label: "1 Day" },
        { value: 1, label: "1 Hour" },
        { value: 6, label: "6 Hours" },
        { value: 168, label: "1 Week"}
    ]

    const optionsForKeyCapture = [
        { value: 0, label: ACCOUNTS.PROTECTION_OPTIONS.OFF },
        { value: 1, label: ACCOUNTS.PROTECTION_OPTIONS.ONLY_DETECTION }
    ]

    const optionsForGhosting = [
        { value: 0, label: ACCOUNTS.PROTECTION_OPTIONS.OFF },
        { value: 1, label: ACCOUNTS.PROTECTION_OPTIONS.ENABLED }
    ]

    if(selectedAccount && selectedAccount.name){
        if(selectedAccount.name === "Vantage Mobility"){
            SETTINGS.MEM_PROTECT_LIST = ''
            SETTINGS.EXCLUSION_LIST = 'Inclusion/Exclusion Lists'
        }else{
            SETTINGS.EXCLUSION_LIST = ''
            SETTINGS.MEM_PROTECT_LIST = 'Inclusion Lists'
        }
    }else{
        SETTINGS.EXCLUSION_LIST = ''
        SETTINGS.MEM_PROTECT_LIST = 'Inclusion Lists'
    }

    if(selectedAccount && selectedAccount.name){
        if(selectedAccount.name === "Vantage Mobility"){
            SETTINGS.MEM_PROTECT_LIST = ''
            SETTINGS.EXCLUSION_LIST = 'Inclusion/Exclusion Lists'
        }else{
            SETTINGS.EXCLUSION_LIST = ''
            SETTINGS.MEM_PROTECT_LIST = 'Inclusion Lists'
        }
    }else{
        SETTINGS.EXCLUSION_LIST = ''
        SETTINGS.MEM_PROTECT_LIST = 'Inclusion Lists'
    }

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}}>
            <TitleContainer style={{marginRight: 0}}>
                <TabTitle>{SETTINGS.TITLE}</TabTitle>
                <StyledSelect
                    label="Account"
                    handleOnChange={accountsStore.setSelectedAccountIndex}
                    options={selectOptions}
                    value={selectedAccount ? selectedAccountIndex : ''}/>
            </TitleContainer>
            <Container disableGutters style={{maxWidth: 'none', marginLeft: 0, marginRight: 'inherit'}}>   
                <Container disableGutters style={{maxWidth: 'none', marginLeft: 0}}>
                    { selectedAccount ? 
                        <List>
                            <ListItem>
                                <CaptionContainer>
                                    <span><b>{ACCOUNTS.PROTECTION_OPTIONS.ONLY_DETECTION}: </b>{ACCOUNTS.PROTECTION_OPTIONS.EXPLANATION.ONLY_DETECTION}</span><br/>
                                    <span><b>{ACCOUNTS.PROTECTION_OPTIONS.DETECTION_AND_BLOCKING}: </b>{ACCOUNTS.PROTECTION_OPTIONS.EXPLANATION.DETECTION_AND_BLOCKING}</span>
                                </CaptionContainer>
                                <Button onClick={handleEnableAllClick}>
                                    {enableAll ? SETTINGS.ENABLE_ALL : SETTINGS.DISABLE_ALL}
                                </Button>
                            </ListItem>

                            { !communityEdition && 
                                <ListItem>
                                    <CaptionContainer>
                                    <span><StyledLink to={'/exclusion-list'}>{"Global Blocking Exclusion List"}</StyledLink></span>
                                    </CaptionContainer>
                                </ListItem>
                            }

                            <ListItem>  
                                <ListItemButton onClick={() => setOpenPrevention(!openPrevention)}>
                                    <HeaderListItem primary={SETTINGS.PREEMPTION.TITLE} />
                                    { openPrevention ? <ExpandLess/> : <ExpandMore/> }
                                </ListItemButton>
                            </ListItem>
                            <StyledLink to={'/memory-protection'}>{SETTINGS.EXCLUSION_LIST}</StyledLink>
                            <ListItem>  
                                <Collapse in={openPrevention} timeout={'auto'} unmountOnExit sx={{width: 'inherit'}}>
                                    <List sx={{pl: 4}}>
                                        <HoverListItem >
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.MEMORY_NOTIFICATIONS.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.MEMORY_NOTIFICATIONS.TOOLTIP}/>
                                            </div>
                                            <StyledSwitch 
                                                checked={selectedAccount.visibility_memory}
                                                onChange={(event, checked) => setMemoryNotificationsAndUpdate(checked)}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.SERVICE_NOTIFICATIONS.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.SERVICE_NOTIFICATIONS.TOOLTIP}/>
                                            </div>
                                            <StyledSwitch 
                                                checked={selectedAccount.visibility_system}
                                                onChange={(event, checked) => setSystemNotificationsAndUpdate(checked)}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.SIMPLE_POWERSHELL_NOTIFICATIONS.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.SIMPLE_POWERSHELL_NOTIFICATIONS.TOOLTIP}/>
                                            </div>
                                            <StyledSwitch 
                                                checked={selectedAccount.visibility_simple_powershell}
                                                onChange={(event, checked) => setSimplePowershellNotificationsAndUpdate(checked)}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <Container disableGutters style={{width: 'auto', marginLeft: 0}}>
                                                <Container disableGutters style={{display: 'flex'}}>
                                                    <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.MEMORY_PROTECTION.TITLE}</SettingTitle>
                                                    <WindowsTooltip />
                                                    <HelpTooltip title={SETTINGS.PREEMPTION.MEMORY_PROTECTION.TOOLTIP}/>
                                                </Container>
                                                { !communityEdition &&
                                                    <StyledLink to={'/memory-protection'}>{SETTINGS.MEM_PROTECT_LIST}</StyledLink>
                                                }
                                            </Container>
                                            
                                            <ModuleSelect
                                                handleOnChange={(value: number) => setMemoryProtectionAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.protection_memory : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.SERVICE_PROTECTION.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.SERVICE_PROTECTION.TOOLTIP}/>
                                            </div>
                                            <ModuleSelect 
                                                handleOnChange={(value: number) => setSystemProtectionAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.protection_system : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.SIMPLE_POWERSHELL_PROTECTION.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.SIMPLE_POWERSHELL_PROTECTION.TOOLTIP}/>
                                            </div>
                                            <ModuleSelect
                                                handleOnChange={(value: number) => setSimplePowershellProtectionAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.protection_simple_powershell : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                        <Container disableGutters style={{width: 'auto', marginLeft: 0}}>
                                                <Container disableGutters style={{display: 'flex'}}>
                                                    <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.GHOSTING_PROTECTION.TITLE}</SettingTitle>
                                                    <WindowsTooltip />
                                                    <LinuxTooltip />
                                                    <HelpTooltip title={SETTINGS.PREEMPTION.GHOSTING_PROTECTION.TOOLTIP}/>
                                                </Container>
                                                { !communityEdition &&
                                                    <StyledLink to={'/ghostinclusion'}>{"Ghosting Inclusion List"}</StyledLink>
                                                }
                                                <br />
                                                { !communityEdition &&
                                                    <StyledLink to={'/ghostexclusion'}>{"Ghosting Excluded Processes List"}</StyledLink>
                                                }
                                            </Container>
                                            {/* <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.PREEMPTION.GHOSTING_PROTECTION.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <LinuxTooltip />
                                                <HelpTooltip title={SETTINGS.PREEMPTION.GHOSTING_PROTECTION.TOOLTIP}/>
                                            </div> */}
                                            <ModuleSelect
                                                handleOnChange={(value: number) => setGhostingProtectionAndUpdate(value)}
                                                options={optionsForGhosting}
                                                value={selectedAccount ? selectedAccount.protection_zerotrust_ringfence : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                    </List>
                                </Collapse>
                            </ListItem>

                            <ListItem>
                                <ListItemButton onClick={() => setOpenDetection(!openDetection)}>
                                    <   HeaderListItem primary={SETTINGS.BLOCKING.TITLE} />
                                    { openDetection ? <ExpandLess/> : <ExpandMore/> }
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <Collapse in={openDetection} timeout={'auto'} unmountOnExit sx={{width: 'inherit'}}>
                                    <List sx={{pl: 4}}>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.BLOCKING.FILE_ENTROPY.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <LinuxTooltip />
                                                <HelpTooltip title={SETTINGS.BLOCKING.FILE_ENTROPY.TOOLTIP}/>
                                            </div>
                                            <ModuleSelect 
                                                handleOnChange={(value: number) => setFileEntropyAnalysisAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.intelligent_file_entropy_analysis : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{"Advanced Content Analysis"}</SettingTitle>
                                                <WindowsTooltip />
                                                <HelpTooltip title={"Deep file content inspection for determining encryption events"}/>
                                            </div>
                                            <ModuleSelect 
                                                handleOnChange={(value: number) => setContentAnalysisAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.advanced_content_analysis : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                        <Container disableGutters style={{width: 'auto', marginLeft: 0}}>
                                                <Container disableGutters style={{display: 'flex'}}>
                                                    <SettingTitle>{SETTINGS.BLOCKING.DECOY.TITLE}</SettingTitle>
                                                    <WindowsTooltip />
                                                    <LinuxTooltip />
                                                    <HelpTooltip title={SETTINGS.BLOCKING.DECOY.TOOLTIP}/>
                                                </Container>
                                                { !communityEdition &&
                                                    <StyledLink to={'/decoylist'}>{"Custom Decoy Names"}</StyledLink>
                                                }
                                            </Container>
                                            {/* <div style={{display: 'flex'}}>
                                                <SettingTitle>{SETTINGS.BLOCKING.DECOY.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <LinuxTooltip />
                                                <HelpTooltip title={SETTINGS.BLOCKING.DECOY.TOOLTIP}/>
                                            </div> */}
                                            <ModuleSelect
                                                handleOnChange={(value: number) => setFilesystemDecoyAndUpdate(value)}
                                                options={optionsForProtection}
                                                value={selectedAccount ? selectedAccount.filesystem_decoys : ''}/>
                                        </HoverListItem>
                                    </List>
                                </Collapse>
                            </ListItem>

                            <ListItem>
                                <ListItemButton onClick={() => setOpenRecovery(!openRecovery)}>
                                    <HeaderListItem primary={SETTINGS.REMEDIATION.TITLE} />
                                    { openRecovery ? <ExpandLess/> : <ExpandMore/> }
                                </ListItemButton>
                            </ListItem>
                            <ListItem>  
                                <Collapse in={openRecovery} timeout={'auto'} unmountOnExit sx={{width: 'inherit'}}>
                                    <List sx={{pl: 4}}>
                                        <HoverListItem>
                                        <Container disableGutters style={{width: 'auto', marginLeft: 0}}>
                                                <Container disableGutters style={{display: 'flex'}}>
                                                    <SettingTitle disabled={communityEdition}>{SETTINGS.REMEDIATION.HIDDEN_ARCHIVAL.TITLE}</SettingTitle>
                                                    <WindowsTooltip />
                                                    <LinuxTooltip />
                                                    <HelpTooltip title={SETTINGS.REMEDIATION.HIDDEN_ARCHIVAL.TOOLTIP}/>
                                                </Container>
                                                { !communityEdition &&
                                                    <StyledLink to={'/backupinclusion'}>{"Included Backup Extensions List"}</StyledLink>
                                                }
                                                <br />
                                                { !communityEdition &&
                                                    <StyledLink to={'/backupexclusion'}>{"Excluded Backup Locations List"}</StyledLink>
                                                }
                                            </Container>
                                            {/* <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.REMEDIATION.HIDDEN_ARCHIVAL.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <LinuxTooltip />
                                                <HelpTooltip title={SETTINGS.REMEDIATION.HIDDEN_ARCHIVAL.TOOLTIP} />
                                            </div> */}
                                            <StyledSwitch 
                                                checked={selectedAccount.ransomware_protection_backup}
                                                onChange={(event, checked) => setRansomwareProtectionAndUpdate(checked)}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                        <Container disableGutters style={{width: 'auto', marginLeft: 0}}>
                                                <Container disableGutters style={{display: 'flex'}}>
                                                    <SettingTitle disabled={communityEdition}>{"Intelligent Hidden Archival Refresh Period"}</SettingTitle>
                                                    <WindowsTooltip />
                                                    <LinuxTooltip />
                                                    <HelpTooltip title={"Controls how long to retain archived files"}/>
                                                </Container>
                                            </Container>
                                            <ModuleSelect
                                                handleOnChange={(value: number) => setRefreshProtectionAndUpdate(value)}
                                                options={optionsForRefresh}
                                                value={selectedAccount ? selectedAccount.backup_refresh_period : ''}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        <HoverListItem>
                                            <div style={{display: 'flex'}}>
                                                <SettingTitle disabled={communityEdition}>{SETTINGS.REMEDIATION.LOCK_HIDDEN_ARCHIVAL.TITLE}</SettingTitle>
                                                <WindowsTooltip />
                                                <LinuxTooltip />
                                                <HelpTooltip title={SETTINGS.REMEDIATION.LOCK_HIDDEN_ARCHIVAL.TOOLTIP} />
                                            </div>
                                            <StyledSwitch 
                                                checked={selectedAccount.lock_ransomware_protection_backup}
                                                onChange={(event, checked) => setLockRansomwareProtectionAndUpdate(checked)}
                                                disabled={communityEdition}/>
                                        </HoverListItem>
                                        {communityEdition && (
                                            <HoverListItem>
                                                <div style={{display: 'flex'}}>
                                                    <SettingTitle>{SETTINGS.REMEDIATION.ENCRYPTION_KEY_CAPTURE.TITLE}</SettingTitle>
                                                    <HelpTooltip title={SETTINGS.REMEDIATION.ENCRYPTION_KEY_CAPTURE.TOOLTIP}/>
                                                </div>
                                                <ModuleSelect
                                                    handleOnChange={(value: number) => setEncryptionKeyCaptureAndUpdate(value)}
                                                    options={optionsForKeyCapture}
                                                    value={selectedAccount ? selectedAccount.encryption_key_capture : ''}/>
                                            </HoverListItem>
                                        )}
                                    </List>
                                </Collapse>
                            </ListItem>
                        </List>
                    : <></>}
                </Container>
            </Container>
        </ScreenContainer>
    )
}

export const ScreenContainer = styled(Container)`
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 23px;
    overflow: auto;
`

export const TabTitle = styled("h1")`
    margin-bottom: 20px;
    margin-left: 0px;
    font-weight: bolder;
`

const HeaderListItem = styled(ListItemText)({
    '& .MuiListItemText-primary': {
        fontSize: 30,
        fontWeight: 'bold'
    }
})

const ModuleSelect = styled(StyledSelect)`
    width: 205px;
`

function HelpTooltip({title} : {title: string}) {
    return <ListTooltip title={title} icon={<HelpOutlineIcon color={'primary'}/>}/>
}

function WindowsTooltip() {
    const theme = useTheme();
    return <ListTooltip title={'Available on Windows'} icon={<span><GrWindows color={theme.palette.primary.main}/></span>}/>
}

function LinuxTooltip() {
    const theme = useTheme();
    return <ListTooltip title={'Available on Linux'} icon={<span><VscTerminalLinux color={theme.palette.primary.main}/></span>}/>
}

function ListTooltip({title, icon} : {title: string, icon: any}) {
    return (
        <ListItemIcon sx={{marginLeft: '20px', minWidth: 'auto'}}>
            <Tooltip title={title} placement="right">
                { icon }
            </Tooltip>
        </ListItemIcon>
    )
}

const CaptionContainer = styled('div')(({ theme }) => ({
    marginBottom: '1rem',
    flex: 1
}))

const HoverListItem = styled(ListItem)(({ theme }) => ({
    "&.MuiListItem-root:hover": {
        backgroundColor: 'rgba(255,255,255,0.1)'
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}))

const SettingTitle = styled('span')<{ disabled?: boolean }>(({ theme, disabled }) => ({
    color: disabled ? "gray" : 'inherit'
}))
