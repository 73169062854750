import React from 'react';
import { inject, observer } from 'mobx-react';
import DataTable from 'react-data-table-component';
import LoadingComponent from 'utils/LoadingComponent';
import { toastSuccess } from 'utils/Toast';

type ExclusionListsTableProps = {
    GhostInclusionStore?: any;
    loadingState?:any;
    dataTable?: any;
    noDataMessage?: any;
    showAccountName?: boolean;
};

class ExclusionListsTable extends React.Component<ExclusionListsTableProps, any> {
    onDeleteEntry = (selectedEntry: any) => {
        const { GhostInclusionStore } = this.props;
        GhostInclusionStore.delete(selectedEntry)
        toastSuccess("Deleted entry")
    }
    onEditEntry = (selectedEntry: any) => {
        const { GhostInclusionStore } = this.props;
        GhostInclusionStore.creatingEntry = 0;
        GhostInclusionStore.setSelectedEntry(selectedEntry);
    }

    render() {
        const columns = [
            {
                name: 'Keyword/phrase in alert to exclude',
                selector: (row: any) => row,
                width: "85%",
            },
            {
                name: '',
                selector: (row: any) => (
                    <>
                        <i
                            className="fas fa-edit"
                            onClick={() => {this.onEditEntry(row)}}
                            style={{ cursor: 'pointer', padding: 10 }}
                        />

                        <i
                            className="fas fa-trash-alt"
                            onClick={() => {this.onDeleteEntry(row)}}
                            style={{ cursor: 'pointer', padding: 10 }}
                        />
                    </>
                ),
                width: "15%",
            },
        ];
        

        return (
            <DataTable columns={columns} data={this.props.dataTable} pagination style={{ borderRadius: '30px' }} 
                noDataComponent={
                    <div style={{ backgroundColor: '#232328', color: '#ffffff', width: '100%', padding: '1.5rem', textAlign: 'center' }}>
                        {this.props.loadingState && this.props.loadingState === true ?
                            <LoadingComponent />
                            :

                            <p> {this.props.dataTable ? 'There are no records to display' : this.props.noDataMessage }</p>
                        }
                    </div>
                }/>
        );
    }
}

export default inject('GhostInclusionStore')(observer(ExclusionListsTable))
