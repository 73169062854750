import { Button } from "@mui/material";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import PROPERTIES from "properties";

const { CLIPBOARD } = PROPERTIES

interface CopyAllButtonProps<T> {
    elements: T[]
    mappingFunction: (element: T) => string
}

export default function CopyAllButton<T>({ elements, mappingFunction }: CopyAllButtonProps<T>) {
    const [copied, setCopied] = useState(false)

    function handleClickCopyAll() {
        const allElements = elements
            .map(mappingFunction)
            .join('\n')
        navigator.clipboard.writeText(allElements)
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
    }
    
    return (
        <Button 
            startIcon={copied ? <CheckIcon fontSize={'small'} color="success"/> : <CopyAllIcon/> }
            onClick={handleClickCopyAll}
            color={copied ? 'success' : 'primary'}>
                { copied ? CLIPBOARD.COPIED : CLIPBOARD.COPY_ALL }
        </Button>
    )
}
