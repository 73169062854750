import { Container, Drawer, List, ListItem, ListItemText } from "@mui/material"
import CopyAllButton from "components/clipboard/CopyAllButton"
import CopyToClipboardButton from "components/clipboard/CopyToClipboardButton"
import PROPERTIES from "properties"
import { Observable, ObservableGroupTree } from "stores/AlertsStore"

const { EVENTS } = PROPERTIES

interface ObservablesDrawerProps {
    openDrawer: boolean
    setOpenDrawer: (open: boolean) => void
    selectedObservableGroupTree: ObservableGroupTree
}

export default function ObservablesDrawer({ openDrawer, setOpenDrawer, selectedObservableGroupTree }: ObservablesDrawerProps) {
    function mapObservable(observable: Observable) {
        return `${observable.name}: ${observable.value}`
    }

    function flattenObservableGroupTree(observableGroupTree: ObservableGroupTree): Observable[] {
        const children = observableGroupTree.children.flatMap((child) => flattenObservableGroupTree(child))
        return observableGroupTree.observables.concat(children)
    }

    function mapObservableGroupTree(observableGroupTree: ObservableGroupTree) {
        const observables = observableGroupTree.observables.map((observable, index) => {
            return (
                <ListItemText key={index}>
                    <span style={{fontWeight: 'bold'}}>{observable.name}: </span>
                    <span>{observable.value}</span>
                    <CopyToClipboardButton value={observable.value}/>
                </ListItemText>
            )
        })
        return (
            <List>
                { observables }
                { observableGroupTree.children && 
                  observableGroupTree.children.length > 0 && 
                  observableGroupTree.children.map((child, index) => {
                    return <ListItem key={index}>{mapObservableGroupTree(child)}</ListItem>
                })}
            </List>
        )
    }

    return (
        <Drawer
            anchor={'right'}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            slotProps={{
                backdrop: {
                    style: { opacity: 0 },
                    
                },
            }}>
            <Container sx={{paddingTop: '10px', minWidth: '400px'}}>
                <h2>{EVENTS.DRAWER.TITLE}</h2>
                <CopyAllButton elements={flattenObservableGroupTree(selectedObservableGroupTree)} mappingFunction={mapObservable}/>
                { mapObservableGroupTree(selectedObservableGroupTree) }
            </Container>
        </Drawer>
    )
}
