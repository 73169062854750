import AuthStore from './AuthStore';
import DevicesStore from './DevicesStore';
import DeviceDetailStore from './DeviceDetailStore';
import WindowsConfigStore from './WindowsConfigStores';
import UserProfileStore from './UserProfileStore';
import UsersStore from './UsersStore';
import AlertsStore from './AlertsStore';
import ProcessesPerUserStore from './ProcessesPerUserStore';
import AccountsStore from './AccountsStore'
import ExclusionListsStore from './ExclusionListsStore';
import GhostExclusionStore from './GhostExclusionStore';
import GhostInclusionStore from './GhostInclusionStore';
import BackupExclusionStore from './BackupExclusionStore';
import BackupInclusionStore from './BackupInclusionStore';
import DecoyStore from './DecoyStore';

export default {
    AccountsStore,
    AuthStore,
    DevicesStore,
    DeviceDetailStore,
    WindowsConfigStore,
    UserProfileStore,
    UsersStore,
    AlertsStore,
    ProcessesPerUserStore,
    ExclusionListsStore,
    GhostExclusionStore,
    GhostInclusionStore,
    BackupExclusionStore,
    BackupInclusionStore,
    DecoyStore
};