import React, { useState } from 'react';
import { DataGrid, GridCallbackDetails, GridCellParams, GridColDef, GridColumnVisibilityModel, GridPaginationModel, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from 'moment';
import PROPERTIES from 'properties';
import { Alert, Observable, ObservableGroupTree, Order } from 'stores/AlertsStore';
import { Button, Chip } from '@mui/material';
import { Category, Severity, useAlertsStoreContext } from 'stores/AlertsProvider';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { useLocalStorage } from 'react-use';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ObservablesDrawer from './ObservablesDrawer';

const { EVENTS, FILTERS } = PROPERTIES

const defaultVisibilityModel = { 
    deviceIdentifier: true,
    timestamp: true,
    threat_type: true,
    description: true,
    tags: true,
    mitigated: true,
}

interface EventsTableProps {
    events: Alert[]
    rowsPerPage: number
    totalEvents: number
    onClickOnTag: (tag: Severity | Category) => void
    onChangePage: (newPage: number, oldPage: number) => void
    onChangeRowsPerPage: (rowsPerPage: number) => void
    onSort: (order: Order, orderBy: string) => void
}

export default function EventsTable({ events, rowsPerPage, totalEvents, onClickOnTag, onChangePage, onChangeRowsPerPage, onSort }: EventsTableProps) {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: rowsPerPage })
    const [openDrawer, setOpenDrawer] = useState(false)
    const [selectedObservableGroupTree, setSelectedObservableGroupTree] = useState<ObservableGroupTree>()
    const [columnVisibility, setColumnVisibility] = useLocalStorage('columns-visibility', defaultVisibilityModel);

    const alertsStore = useAlertsStoreContext()

    const columns: GridColDef<Alert>[] = [
        { field: 'actions', headerName: EVENTS.ACTIONS, flex: 0.5, sortable: false, align: 'center', headerAlign: 'center', minWidth: 50, maxWidth: 100, renderCell: renderActions },
        { field: 'deviceIdentifier', headerName: EVENTS.DEVICE_IDENTIFIER, flex: 1, renderCell: renderDeviceIdentifier, sortable: false },
        { field: 'timestamp', headerName: EVENTS.TIME, flex: 1, renderCell: renderTimestamp, sortable: true },
        { field: 'threat_type', headerName: EVENTS.SUMMARY, flex: 1, sortable: false },
        { field: 'description', headerName: EVENTS.DESCRIPTION, flex: 1, sortable: false },
        { field: 'tags', headerName: EVENTS.TAGS, flex: 1, renderCell: renderTags, sortable: false, align: 'center', headerAlign: 'center' },
        { field: 'mitigated', headerName: EVENTS.MITIGATED, flex: 0.5, renderCell: renderMitigated, sortable: false, align: 'center', headerAlign: 'center', minWidth: 50, maxWidth: 100 },
    ]

    function renderDeviceIdentifier(params: GridCellParams) {
        const event = params.row as Alert
        return event ? event.device ? event.device.hostname ? `${event.device.hostname}/${event.device.ip_address}`: 'no hostname' : 'no device' : 'no event'
    }

    function renderTimestamp(params: GridCellParams) {
        const event = params.row as Alert
        return moment.unix(event.timestamp).format('MM/DD/YYYY, h:mm a')
    }

    function renderTags(params: GridCellParams) {
        const event = params.row as Alert
        return <>
            <Chip label={FILTERS.SEVERITY[translateSeverity(event.severity) as Severity]} clickable onClick={() => onClickOnTag(translateSeverity(event.severity) as Severity)} />
            <Chip label={FILTERS.CATEGORY[event.category as Category]} sx={{margin: '5px 5px'}} clickable onClick={() => onClickOnTag(event.category as Category)}/>
        </>
    }

    function renderMitigated(params: GridCellParams) {
        const event = params.row as Alert
        return event.category === 'detection' && event.mitigated !== null ?
            event.mitigated ?
                <BsCheckCircleFill color="green" /> :
                <BsXCircleFill color="red" />
            : 'N/A'
    }

    function renderActions(params: GridCellParams) {
        const event = params.row as Alert
        const observablesArePresent = event.observable_group_trees && event.observable_group_trees.length > 0
        return (
            <Button 
                disabled={!observablesArePresent}
                onClick={() => handleClickOpenDrawer(event.observable_group_trees[0])}>
                <VisibilityIcon />
            </Button>
        )
    }

    function handleClickOpenDrawer(observableGroupTree: ObservableGroupTree) {
        setSelectedObservableGroupTree(observableGroupTree)
        setOpenDrawer(true)
    }

    function handlePaginationModelChange(model: GridPaginationModel, details: GridCallbackDetails<"pagination">) {
        if (paginationModel.page !== model.page)
            onChangePage(model.page, paginationModel.page)
        if (paginationModel.pageSize !== model.pageSize)
            onChangeRowsPerPage(model.pageSize)
        setPaginationModel(model)
    }

    function handleColumnVisibilityChange(model: GridColumnVisibilityModel, _details: GridCallbackDetails) {
        setColumnVisibility((previous) => {
            if (Object.keys(model).length === 0)
                return defaultVisibilityModel
            return {
                ...defaultVisibilityModel,
                ...previous,
                ...model
            }
        })
    }

    function handleSortModelChange(model: GridSortModel, _details: GridCallbackDetails) {
        if (model.length !== 0) {
            onSort(model[0].sort as Order, model[0].field)
            setPaginationModel({
                pageSize: rowsPerPage,
                page: 0
            })
        }
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button startIcon={<FileDownloadIcon />} onClick={alertsStore.downloadAlertsCSV}>
                {EVENTS.EXPORT}
            </Button>
          </GridToolbarContainer>
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={events}
                rowCount={totalEvents}
                columns={columns}
                pageSizeOptions={[10, 15, 20, 25, 30]}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModelChange}
                slots={{ toolbar: CustomToolbar}}
                disableColumnFilter
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                sortingMode='server'
                onSortModelChange={handleSortModelChange}
                paginationModel={paginationModel}
                sortingOrder={['asc', 'desc']}
                initialState={{
                    sorting: {
                        sortModel: [{
                            field: 'timestamp',
                            sort: 'desc'
                        }]
                    }
                }}
                sx={{
                    '& .MuiDataGrid-toolbarContainer': {
                        backgroundColor: '#121212',
                        borderBottom: '1px solid rgba(81,81,81,1)'
                    }
                }}/>
            { selectedObservableGroupTree && 
                <ObservablesDrawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                selectedObservableGroupTree={selectedObservableGroupTree}/>
            }
        </div>
    );
}

function translateSeverity(severity: string): string {
    if (severity === 'critical')
        return 'ransomware'
    if (severity === 'notice' || severity === 'informational' || severity === 'debug')
        return 'siem'
    return 'ids'
}
