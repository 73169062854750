import { observable, action, makeObservable, computed } from 'mobx';
import { KEYS, getSavedValue, deleteSavedValue, saveValue } from 'utils/LocalStorage';
import { makeRequest, makeAuthenticatedRequest } from 'utils/API';

export type User = {
    id: string
    email: string
    first_name: string
    last_name: string
    role: string
    account_id?: string
    date_created?: string
    alerts_email?: string
    password?: string
};

export type Account = CompressedAccount & {
    parent_account_id: string
    subscription_months: number;
    date_last_subscription: Date;
    users_limit: number;
    devices_limit: number;
    sub_accounts: Account[]
    protection_memory: number
    protection_simple_powershell: number
    protection_system: number
    protection_advanced_powershell: number
    protection_zerotrust_ringfence: number
    backup_refresh_period: number
    advanced_content_analysis: number
    visibility_advanced_powershell: boolean
    visibility_simple_powershell: boolean
    visibility_system: boolean
    visibility_memory: boolean
    ransomware_protection_backup: boolean,
    lock_ransomware_protection_backup: boolean,
    filesystem_decoys: number,
    intelligent_file_entropy_analysis: number,
    encryption_key_capture: number
};

export type CompressedAccount = {
    id: string;
    name: string;
}

type AuthResponse = {
    access_token: string;
    user: User
};

type APIKeyResponse = {
    api_key: string;
    user: User
};

export class AuthStore {
    loading: boolean;
    apikey:string;
    serviceapikey:string;

    isAuthenticated: boolean;
    
    user: User|null;
    accountName: string;
    parentAccount: string;
    accountentropyscore:number;

    constructor() {
        makeObservable(this, {
            loading: observable,
            isAuthenticated: observable,
            user: observable,
            apikey:observable,
            serviceapikey:observable,
            accountName:observable,
            parentAccount:observable,
            accountentropyscore:observable,

            logout: action,
            setAuthenticationData: action,
            setUserData: action,
            login: action,
            createServiceUser: action,
            loadProfile: action,
            loadAPIKey: action,
            loadUserAPIKey: action,
            createAPIToken: action,
            createUserAPIToken: action,
            deleteAPIToken: action,
            sendEmail: action,
            resetPassword: action,

            isSupervisor: computed,
            isAdmin: computed
        });

        this.loading = false;
        this.accountentropyscore=-1;
        this.apikey="";
        this.serviceapikey=""
        this.isAuthenticated = getSavedValue(KEYS.JWT) !== null;
        this.user = getSavedValue(KEYS.USER, true) || null;
        this.accountName = ""
        this.parentAccount = ""
    }

    get isSupervisor() {
        return this.user?.role === 'supervisor';
    }

    get isAdmin() {
        return this.isSupervisor || this.user?.role === 'admin' ;
    }

    get isUser() {
        return this.isAdmin || this.user?.role === 'user';
    }

    logout = (): void => {
        // TODO: API?
        deleteSavedValue(KEYS.JWT);
        deleteSavedValue(KEYS.USER);
        
        this.isAuthenticated = false;
        this.user = null;

        window.location.href = '/login';
    }

    setAuthenticationData = ({ access_token, user }: AuthResponse) => {
        this.loading = false;
        saveValue(KEYS.JWT, access_token);
        saveValue(KEYS.USER, user, true);
        
        this.isAuthenticated = true;
        this.user = user;

        window.location.href = '/';
    }

    setUserData = (user: User) => {
        saveValue(KEYS.USER, user, true);
        this.user = user;
    }

    sendEmail = (email: string): Promise<void> => {
        if (this.isAuthenticated)
            return Promise.resolve()

        const url =`/api/v2/auth/password-recovery/${email}`

        return makeRequest({ 
            url, 
            data: "",
            options: { 
                method: 'POST',
                headers: {
                    'accept': 'application/json'
                }
            },
            stringifyData: false
        })
        .catch(e => {
            throw e
        })
    }

    resetPassword = (credentials: { email: string, token:string,new_password:string }): Promise<void> => {
        if (this.isAuthenticated) {
            return Promise.resolve();
        }

        return makeRequest({ 
            url: '/api/v2/auth/reset-password/', 
            data: credentials,
            options: { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept':'application/json'
                }
            }
        })
            .catch(e => {
                throw e;
            });
    }

    login = (credentials: { username: string, password: string }): Promise<void> => {
        if (this.isAuthenticated) {
            return Promise.resolve();
        }

        this.loading = true;

        return makeRequest({ 
            url: '/api/v2/auth/login', 
            data: new URLSearchParams(credentials).toString(),
            options: { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },
            stringifyData: false
        })
            .then(this.setAuthenticationData)
            .catch(e => {
                this.loading = false;

                throw e;
            });
    }

    loginSSO = async (token: string): Promise<void> => {
        this.loading = true

        return makeRequest({
            url: '/api/v2/auth/firebase/login',
            options: {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` }
            }
        })
        .then(this.setAuthenticationData)
        .catch(e => {
            this.loading = false
            throw e
        })
    }

    createServiceUser = (credentials: { 
        email: string, 
        password: string,
        first_name: string,
        last_name: string,
        account_id: string,
        role: string
    }): Promise<void> => {

        this.loading = true;
        var signupurl = '/api/v2/accounts/' + credentials.account_id + '/users'
        return makeRequest({ 
            url: signupurl, 
            data: credentials,
            options: { method: 'POST' }
        })
            .then()
            .catch(e => {
                this.loading = false;

                throw e;
            });
    }
    

    loadProfile = (): Promise<any> => {
        this.loading = true;
        
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${this.user?.account_id}/users/${this.user?.id}`, 
            options: { method: 'GET' }
        })
            .then((data: User) => {
                this.setUserData(data);
                this.loading = false;
            })
            .catch(e => {
                this.loading = false;

                throw e;
            });
    }

    loadMyAccount = (): Promise<any> => {
        this.loading = true;
        
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${this.user?.account_id}`, 
            options: { method: 'GET' }
        })
            .then((data: Account) => {
                this.accountName=data.name
                if(data.parent_account_id != null){
                    this.parentAccount=data.parent_account_id
                }
                this.loading = false;
            })
            .catch(e => {
                this.loading = false;
                throw e;
            })
    }

    loadAPIKey = (): Promise<any> => {
        return makeAuthenticatedRequest({
            url: `/api/v2/auth/api-key/${this.user?.id}`
        })
        .then((data: APIKeyResponse) => {
            if (data)
                this.apikey=data.api_key
            else
                this.apikey=""
        })
        .catch(e => {
            throw e
        })
    }

    createAPIToken = (): Promise<any> => {
        return makeAuthenticatedRequest({
            url: `/api/v2/auth/api-key/${this.user?.id}`, 
            options: { method: 'POST' }
        })
        .then((data: APIKeyResponse) => {
            if (data)
                this.apikey=data.api_key
            else
                this.apikey=""
        })
        .catch(e => {
            throw e
        })
    }

    createUserAPIToken = (Userid: string): Promise<any> => {
        var apikeyurl = `/api/v2/auth/api-key/` + Userid

        return makeAuthenticatedRequest({
            url: apikeyurl, 
            options: { method: 'POST' }
        })
        .then()
        .catch(e => {
            throw e
        })
    }

    loadUserAPIKey = (Userid: string): Promise<any> => {
        var apikeyurl = `/api/v2/auth/api-key/` + Userid

        return makeAuthenticatedRequest({
            url: apikeyurl, 
            options: { method: 'GET' }
        })
        .then((data: APIKeyResponse) => {
            if (data)
                this.serviceapikey=data.api_key
            else
                this.serviceapikey=""
        })
        .catch(e => {
            throw e
        })
    }

    deleteAPIToken = (): Promise<any> => {
        return makeAuthenticatedRequest({
            url: `/api/v2/auth/api-key/${this.user?.id}`, 
            options: { method: 'DELETE' }
        })
        .then(() => {
            this.apikey=""
        })
        .catch(e => {
            throw e
        })
    }
}

const STORE = new AuthStore();

export default STORE;
