import React,{useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'App.scss';

import AllStores from 'stores/all';

import AuthenticatedRoute from 'components/AuthenticatedRoute';

import Login from 'views/login/Login';
import ResetPassword from 'views/login/ResetPassword';
import Main from 'views/main/Main';
import NotFound from 'views/NotFound/NotFound';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import StoresProvider from 'stores/StoresProvider';
import AlertsProvider from 'stores/AlertsProvider';
import ProcessesProvider from 'stores/ProcessesProvider';
import DevicesProvider from 'stores/DevicesProvider';
import AccountsProvider from 'stores/AccountsProvider';
import WindowsConfigsProvider from 'stores/WindowsConfigsProvider';
import SingleSignOnProvider from 'stores/SingleSignOnProvider';
import { makeAuthenticatedRequest } from 'utils/API';
import DeviceDetailProvider from 'stores/DeviceDetailProvider';

export const mainTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#dc3545',
      dark: '#dc3545'
    }
  },
  typography: {
    fontFamily: 'Varela Round'
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(97,97,97,1)'
        }
      } 
    }
  }
})

function App() {

    useEffect(() => {
      var scripta = document.createElement('script')

      scripta.text = `
        window.fwSettings={
          'widget_id':156000000320,
          'locale': 'en'
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        `

        scripta.type = 'text/javascript'
        document.head.appendChild(scripta);
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://widget.freshworks.com/widgets/156000000320.js';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
  
    },[]);

  
  const authStore = AllStores.AuthStore;


  useEffect(() => {
    if(authStore.isAuthenticated){
      (window as any)?.FreshworksWidget('show', 'launcher');
      const myname = authStore.user?.first_name + ' ' + authStore.user?.last_name;
      const myemail = authStore.user?.email;

      var authenticateCallback = function() {
        makeAuthenticatedRequest({
            url: `/api/v2/auth/freshdesk/login`,
            options: { method: 'POST' },
        }).then(data => {
            (window as any)?.FreshworksWidget('authenticate', {
              token: data.access_token
            });
          });
      }

      makeAuthenticatedRequest({
        url: `/api/v2/auth/freshdesk/login`,
        options: { method: 'POST' },
    }).then(data => {
        (window as any)?.FreshworksWidget('authenticate', {
          token: data.access_token,
          callback: authenticateCallback
        });
      });
    }else{
      (window as any)?.FreshworksWidget('logout');
      (window as any)?.FreshworksWidget('hide', 'launcher');
    }
  }, [authStore.isAuthenticated]);


  return (
    <ThemeProvider theme={mainTheme} >
      <CssBaseline />
      <SingleSignOnProvider>
      <WindowsConfigsProvider>
      <AccountsProvider>
      <DeviceDetailProvider>
      <DevicesProvider>
      <AlertsProvider>
      <ProcessesProvider>
      <StoresProvider>
        <div className="App">
          <Provider {...AllStores}>
            <ToastContainer />


            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/resetpassword" element={<ResetPassword />} />

              <Route
                path="/*"
                element={
                  <AuthenticatedRoute>
                    <Main />
                  </AuthenticatedRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Provider>
        </div>
      </StoresProvider>
      </ProcessesProvider>
      </AlertsProvider>
      </DevicesProvider>
      </DeviceDetailProvider>
      </AccountsProvider>
      </WindowsConfigsProvider>
      </SingleSignOnProvider>
    </ThemeProvider>
  );
}

export default App;
