import { ButtonBase, styled, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";
import CheckIcon from '@mui/icons-material/Check';
import PROPERTIES from "properties";

const { CLIPBOARD } = PROPERTIES

export default function CopyToClipboardButton({ value }: { value: string }) {
    const [copied, setCopied] = useState(false)

    function handleClick() {
        navigator.clipboard.writeText(value)
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
    }

    return (    
        <StyledButton onClick={handleClick} disableRipple>
            { copied ?
                <Tooltip title={CLIPBOARD.COPIED} placement="bottom">
                    <CheckIcon fontSize={'small'} color="success"/>
                </Tooltip> :
                <Tooltip title={CLIPBOARD.COPY_VALUE} placement="bottom">
                    <ContentCopyIcon fontSize={'small'}/>
                </Tooltip>
            }
        </StyledButton>
    )
}

const StyledButton = styled(ButtonBase)(({ theme }) => ({
    marginLeft: '10px',
    color: 'gray',
    '&:hover': {
        color: theme.palette.primary.main
    }
}))
